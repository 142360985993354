*,
*:before,
*:after {
	box-sizing: border-box;
	user-select: none;
}

html,
body,
#root {
	margin: 0;
	padding: 0;
	height: 100%;
	background-color: black;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* Sections */
section,
article,
aside,
h1,
h2,
h3,
h4,
h5,
h6,
address,
p,
/* Tables */
caption,
td,
th,
/* Forms */
legend,
label,
input,
textarea,
output,
.allow-select {
	user-select: text;
}

/* Grouping content */
pre,
blockquote,
ol,
ul,
li,
dl,
dt,
dd,
figure,
figcaption,
ins,
del,
/*  Text-level semantics */
a,
em,
strong,
small,
s,
cite,
q,
dfn,
abbr,
time,
code,
var,
samp,
kbd,
sub,
sup,
i,
b,
u,
mark,
ruby,
rt,
rp,
bdi,
bdo,
span,
.inherit-select {
	user-select: inherit;
}
