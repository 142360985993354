.Spinner {
	width: 30%;
	animation: spin 1s linear infinite;
	color: hotpink;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
