$bg-color: #0c0d0f; //#282c34;
$bg-color1: $bg-color; //#282c34;
$bg-color2: black; //#282c34;

html,
body,
#root {
	background-color: $bg-color;
}
#root {
	background-image: linear-gradient(
		45deg,
		$bg-color2 25%,
		$bg-color1 25%,
		$bg-color1 50%,
		$bg-color2 50%,
		$bg-color2 75%,
		$bg-color1 75%,
		$bg-color1 100%
	);
	background-size: 120px 120px;
}

.App {
	text-align: center;
	// background-color: $bg-color;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;

	h1.fypm {
		letter-spacing: -3px !important;
		text-shadow: 0 2px 4px rgba(black, 0.5);
		color: white;
		margin: 0;

		em {
			font-family: Serif;
			font-weight: 300;
			color: yellow;
		}
	}
	.strapline {
		font-style: italic;
		font-size: 0.8em;
		margin-bottom: 3em;
		color: hotpink;
		letter-spacing: 1px;
	}
	.info {
		font-style: italic;
		font-size: 0.5em;
		margin-top: 3em;
		color: yellow;
	}
	a {
		text-decoration: underline;
		color: hotpink;
		cursor: pointer;
		&:hover {
			color: yellow;
		}
		&:active {
			color: skyblue;
		}
	}
	.smallText {
		font-size: 0.5em;
	}
	b {
		color: hotpink;
	}
}

.signIn {
	color: yellow;
}

.signInBox {
	padding: 1ex;
	min-width: 25ex;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.Home,
.Page {
	background-color: black;
	padding: 2ex;
	border: 2px solid $bg-color;
	border-radius: 1ex;
	box-shadow: 0 3px 6px black;
}

.Home {
	position: relative;
	.Logo {
		position: absolute;
		width: 96px;
		height: 96px;
		top: -64px;
		left: calc(50% - 48px);
	}
	.MoneyBubble {
		position: absolute;
		width: 56px;
		height: 49px;
		top: -56px;
		left: calc(50% + 33px);
		animation: pulse 5s infinite ease-in;
		transform-origin: left bottom;
	}
}
@keyframes pulse {
	0% {
		transform: scale(0) translateX(0) translateY(0) rotate(0);
		opacity: 1;
	}
	15% {
		transform: scale(1.25) translateX(0) translateY(0) rotate(12deg);
		opacity: 1;
	}
	17% {
		transform: scale(1.25) translateX(0) translateY(0) rotate(6deg);
		opacity: 1;
	}
	19% {
		transform: scale(1.25) translateX(0) translateY(0) rotate(14deg);
		opacity: 1;
	}
	20% {
		transform: scale(1.25) translateX(0) translateY(0) rotate(12deg);
		opacity: 1;
	}
	30% {
		transform: scale(1.25) translateX(0) translateY(0) rotate(12deg);
		opacity: 1;
	}
	50% {
		transform: scale(1.25) translateX(25px) translateY(-75px) rotate(-24deg);
		opacity: 0;
	}
	100% {
		transform: scale(1.25) translateX(25px) translateY(-75px) rotate(-24deg);
		opacity: 0;
	}
}

.Page {
	margin: 4em 4em;
	h1 {
		color: hotpink;
		text-shadow: 0 3px 6px black;
	}
	&.text {
		text-align: left;
	}
	.back {
		transform: rotate(180deg);
		display: inline-block;
	}
}
