$highlight-color: hotpink;
$text-color: white;

form {
	input,
	select,
	option,
	textarea {
		border-radius: 5px;
		background-color: inherit;
		color: white;
		border: none;
		font-size: 16px;
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none;
	}

	/* Change Autocomplete styles in Chrome*/
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		-webkit-text-fill-color: $highlight-color;
		-webkit-box-shadow: 0 0 0px 1000px #000 inset;
	}

	// input::-webkit-contacts-auto-fill-button {
	// 	visibility: hidden;
	// 	display: none !important;
	// 	pointer-events: none;
	// 	position: absolute;
	// 	right: 0;
	// }

	.Input {
		margin-bottom: 0.5ex;
		display: flex;
		align-items: center;
		background-color: black;
		border: 2px solid rgba($highlight-color, 0.5);
		color: $text-color;
		border-radius: 5px;
		font-size: 16px;
		padding: 1.5ex;
		width: 100%;

		input {
			flex-grow: 1;
		}

		svg.icon {
			margin-right: 1ex;
			width: 3.2ex;
		}
	}
	.Button.Submit {
		display: flex;
		align-items: center;
		justify-items: stretch;

		input[type='submit'] {
			background-color: rgba($highlight-color, 0.8);
			border: none;
			box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
			font-weight: 700;
			text-transform: uppercase;
			text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.25);
			padding: 1.5ex;
			flex-grow: 1;

			cursor: pointer;

			&:hover {
				background-color: rgba($highlight-color, 1);
				transform: scale(1.02);
			}
			&:active {
				transform: scale(0.98);
				box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
			}
		}
	}
}
